import { render, staticRenderFns } from "./BrandRelatedArticles.vue?vue&type=template&id=5e4c3e80&scoped=true"
import script from "./BrandRelatedArticles.vue?vue&type=script&lang=js"
export * from "./BrandRelatedArticles.vue?vue&type=script&lang=js"
import style0 from "./BrandRelatedArticles.vue?vue&type=style&index=0&id=5e4c3e80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e4c3e80",
  null
  
)

export default component.exports